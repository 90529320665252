import { Page } from 'lemans-brandsites-common/components/Page';
import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import { pageRestHandleSideEffects } from 'lemans-brandsites-common/libs/apiHandleSideEffects';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { brandName } from 'theme/libs/config';
import { FourOhFour } from '../FourOhFour';
import './PrivacyPolicy.styles.less';
import { IPrivacyPolicyProps } from "./PrivacyPolicy.types";

export const PrivacyPolicy = (props:IPrivacyPolicyProps) => {
    const [content, setContent] = useState<string>('');
    const [fourOhFour, isLoading, data] = pageRestHandleSideEffects('privacy-policy');
    const [title, setTitle] = useState<string>('');

    useEffect(() => {
        setContent(data?.content.rendered ? data?.content.rendered : '');
        setTitle(data?.title.rendered ? data?.title.rendered : '');
    }, [data]);

    return <>
        {fourOhFour && <FourOhFour />}
        {isLoading && <Spinner defaultIndicator={true} size="large" />}
        {!isLoading && !fourOhFour && data &&
            <Page className="Privacy Policy" title={`${brandName} - ${title}`}>
                <div className="container">
                    <h1>{title}</h1>
                </div>
                <div className="container" dangerouslySetInnerHTML={{ __html: content }}/>
            </Page>
        }
    </>
}
