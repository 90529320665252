import { Col, Row } from 'antd';
import { Page } from 'lemans-brandsites-common/components/Page';
import { SpinnerCss } from 'lemans-brandsites-common/components/SpinnerCss';
import { apiConfig } from 'lemans-brandsites-common/libs/config';
import { onlineDealer } from 'lemans-brandsites-common/libs/DealerLocatorApi';
import { IDealerData } from 'lemans-brandsites-common/libs/DealerLocatorApi/libs/OnlineDealer/onlineDealer.types';
import * as React from 'react';
import { brandName } from 'theme/libs/config';
import { OnlineDealerItem } from './OnlineDealerItem';
import './OnlineDealers.styles.less';
import {IOnlineDealersProps} from "./OnlineDealers.types";

export const OnlineDealers = (props:IOnlineDealersProps) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [onlineDealerData, setOnlineDealerData] = React.useState<IDealerData[] | undefined>(undefined);

    const getData = async () => {
        setIsLoading(true);
        try {
            const [dealerRes] = await Promise.all([onlineDealer(apiConfig)]);
            setOnlineDealerData(dealerRes);
        }
        finally {
            setIsLoading(false);
        }
    }

    React.useEffect(() => {
        getData();
    }, []);

    return (
        <>
            {isLoading && <SpinnerCss />}
            { !isLoading &&
                <Page className="OnlineDealerSearch" title={`${brandName} - Online Dealers`}>
                    <Row
                        align={'bottom'}
                        justify="space-around"
                    >
                        <Col xs={18}>
                            <div className={'online-dealers-wrapper--div'} >
                                <h1>Online Dealers</h1>
                                <Row gutter={16}>
                                    {Array.isArray(onlineDealerData) &&
                                        onlineDealerData.filter(dealer => dealer.dealerWebSiteUrl).map(dealer =>
                                            <Col key={dealer.dealerName} xl={8} lg={12} md={24}>
                                                <OnlineDealerItem data={dealer} />
                                            </Col>
                                        )}
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Page>
            }
        </>
    );
}
