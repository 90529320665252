import { Page } from 'lemans-brandsites-common/components/Page';
import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import { SplitPage } from 'lemans-brandsites-common/components/SplitPage';
import { pageRestHandleSideEffects } from 'lemans-brandsites-common/libs/apiHandleSideEffects';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { brandName } from 'theme/libs/config';
import { FourOhFour } from '../FourOhFour';
import './About.styles.less';
import { IAboutCustomFields, IAboutProps } from "./About.types";

export const About = (props: IAboutProps) => {
    const [content, setContent] = useState<string>('');
    const [customFields, setCustomFields] = useState<IAboutCustomFields>();
    const [fourOhFour, isLoading, data] = pageRestHandleSideEffects('about');
    const [title, setTitle] = useState<string>('');

    useEffect(() => {
        setContent(data?.content.rendered ? data?.content.rendered : '');
        setCustomFields(data?.acf);
        setTitle(data?.title.rendered ? data?.title.rendered : '');
    }, [data]);

    return <>
        {fourOhFour && <FourOhFour />}
        {isLoading && <Spinner defaultIndicator={true} size="large" />}
        {!isLoading && !fourOhFour && data &&
            <Page className="About" title={`${brandName} - ${title}`}>
                <SplitPage
                    imageUrl={customFields?.side_banner}
                    title={title}
                >
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                </SplitPage>
            </Page>
        }
    </>
}
