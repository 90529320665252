import * as React from 'react';
import { Link } from 'react-router-dom';
import './SectionTitle.styles.less';
import { ISectionTitleProps } from "./SectionTitle.types";

export const SectionTitle = (props: ISectionTitleProps) =>
    <div className={`SectionTitle side-${props.side} vertical-pos-${props.verticalPos}`}>
        <div className="section-title-wrapper">
            <div className="section-title section-title--large">
                <span className="underline-accent">
                    {props.title}
                </span>
            </div>
        </div>
        <div className="section-tagline">
            {props.tagline}
        </div>
        { props.buttonUrl && props.buttonUrlText &&
            <div className="section-cta">
                <Link to={props.buttonUrl}>{props.buttonUrlText}</Link>
            </div>
        }
    </div>;
