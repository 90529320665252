import { apiHandleSideEffects } from 'lemans-brandsites-common/libs/apiHandleSideEffects';
import * as React from 'react';
import { useEffect, useState } from 'react';
import 'theme/components/Videos/Videos.styles.less';
import { SpinnerCss } from '../SpinnerCss';
import { IVideosProps } from "./Videos.types";

export const Videos = (props: IVideosProps) => {
    const [, isLoading, data] = apiHandleSideEffects(
        `https://www.googleapis.com/youtube/v3/playlistItems?playlistId=${props.youTubePlaylistId}&key=${props.youTubeKey}&part=snippet&maxResults=50`
    );
    const [currentVideo, setCurrentVideo] = useState<IYouTubeVideo>();
    const [videos, setVideos] = useState<IYouTubeVideo[]>([]);

    useEffect(() => {
        setVideos(data?.items);
        setCurrentVideo(data?.items[0]);
    }, [data]);

    useEffect(() => {
        console.log('isLoading updated', isLoading);
    }, [isLoading]);

    const handleVideoThumbnailClick = (i: number) => () => {
        setCurrentVideo(videos[i]);
    }

    return (
        <>
            { isLoading && <SpinnerCss />}
            { !isLoading && !!videos &&
                <div className="Videos">
                    { currentVideo &&
                        <>
                            <div className="video-wrapper">
                                <iframe
                                    allowFullScreen
                                    className="video"
                                    frameBorder={0}
                                    src={`https://www.youtube.com/embed/${currentVideo.snippet.resourceId.videoId}?autoplay=1`}
                                    allow="autoplay"
                                />
                            </div>
                            <div className="video-list-wrapper">
                                <ul className="video-list">
                                    { videos && videos.length
                                            ? videos.map((video: any, i: number) => (
                                                <li className="video" key={i} onClick={handleVideoThumbnailClick(i)}>
                                                    <img className="video__thumbnail" src={video.snippet.thumbnails.medium.url} alt={video.snippet.title} />
                                                    <div className="video__title-wrapper">
                                                        <div className="video__title">{video.snippet.title}</div>
                                                    </div>
                                                </li>
                                            ))
                                            : null
                                    }
                                </ul>
                            </div>
                        </>
                    }
                </div>
            }
        </>
    );
}

interface IYouTubeVideo {
    etag: string;
    id: string;
    kind: string;
    snippet: IYouTubeVideoSnippet;
}

interface IYouTubeVideoSnippet {
    channelId: string;
    channelTitle: string;
    description: string;
    playlistId: string;
    position: number;
    publishedAt: string;
    resourceId: { kind: string, videoId: string };
    thumbnails: IYouTubeVideoSnippetThumbnails;
    title: string;
}

interface IYouTubeVideoSnippetThumbnails {
    default: IYouTubeVideoSnippetThumbnailsOptions;
    high: IYouTubeVideoSnippetThumbnailsOptions;
    maxres: IYouTubeVideoSnippetThumbnailsOptions;
    medium: IYouTubeVideoSnippetThumbnailsOptions;
    standard: IYouTubeVideoSnippetThumbnailsOptions;
}

interface IYouTubeVideoSnippetThumbnailsOptions {
    height: number;
    url: string;
    width: number;
}
