import { Col, Row } from 'antd';
import { imageUrl } from 'theme/libs/util';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { productUrl } from 'theme/libs/routes';
import './ProductRelatedProducts.styles.less';
import { IProductRelatedProductsProps } from "./ProductRelatedProducts.types";

export const ProductRelatedProducts = (props: IProductRelatedProductsProps) => 
    <div className="ProductRelatedProducts">
        <h2>{props.title}</h2>
        <Row gutter={[16, 0]} className="section">
            {props.relatedProducts.map((product, i) =>
                <Col
                    key={`product-related-product-${product.entityId}-${i}`}
                    span={4}
                >
                    <Link to={productUrl(product.relatedId.toString())}>
                        <img
                            src={imageUrl(product, { x: 330, y: 330, t: 'image/jpeg' })}
                            title={product.relatedName}
                        />
                    </Link>
                </Col>
            )}
        </Row>
    </div>;
