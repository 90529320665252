import * as React from 'react';
import './ProductTitle.styles.less';
import { IProductTitleProps } from "./ProductTitle.types";

export const ProductTitle = (props: IProductTitleProps) => {
    const renderProductTitle = (title: string) => {
        const titleArr = title.split(' ');

        return titleArr.length > 0
            ? titleArr.map((word, i) =>
                <span key={i}>{word}</span>
            )
            : <span>{title}</span>;
    };

    return <div className="ProductTitle">
            <div className={ !props.caption ? "product-title__title" : "product-title__title product-title__title--has-subtitle"}>
                <span className="product-title__title-border product-title__title-border--top product-title__title-border--bottom">
                    {renderProductTitle(props.title)}
                </span>
            </div>
            {props.caption &&
                <div className="product-title__caption">
                    <span>
                        {props.caption}
                    </span>
                </div>
            }
    </div>
}
