import { Col, Row } from 'antd';
import { imageUrl } from 'theme/libs/util';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { productUrl } from 'theme/libs/routes';
import './ProductOptions.styles.less';
import { IProductOptionsProps } from "./ProductOptions.types";

const getAlignItemsClass = (position:string) => {
    switch (position) {
        case 'left': {
            return 'product-option--left';
        }
        case 'center': {
            return 'product-option--center';
        }
        case 'right': {
            return 'product-option--right';
        }
        default: {
            return '';
        }
    }
}

export const ProductOptions = (props: IProductOptionsProps) => props.productGroups.length > 0
    ? <div className="ProductOptions">
        <h2>{props.title}</h2>
        <Row gutter={[16, 16]}>
            {props.productGroups.map((product, i) =>
                <Col
                    className={`product-option ${getAlignItemsClass(props.alignItems)}`}
                    key={`product-option-${product.entityId}-${i}`}
                    span={6}
                >
                    {product.productGroupId &&
                        <Link to={productUrl(product.productGroupId.toString())}>
                            <div className="product-option__image">
                                <img
                                    src={imageUrl(product, { x: 180, y: 180, t: 'image/jpeg' })}
                                    title={product.entityName}
                                />
                            </div>
                            {props.showOptionTitle && product.entityName &&
                                <div className="product-option__title">
                                    {product.entityName}
                                </div>
                            }
                        </Link>
                    }
                </Col>
            )}
        </Row>
    </div>
    : null;
