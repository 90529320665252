import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { navSections } from '../Header/Header.helpers';
import { Logo } from '../Logo';
import './NavMobile.styles.less';
import { INavMobileProps } from "./NavMobile.types";

export const NavMobile = (props: INavMobileProps) => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const location = useLocation();
    const [currentNavId, setCurNavId] = useState('');

    React.useEffect(() => {
        setIsNavOpen(false);
        setCurNavId("");
    }, [location.pathname]);

    const setCurNav = (sectionId: string) => (e: any) => {
        setCurNavId(sectionId);
    }

    const toggleIsNavOpen = () => {
        setIsNavOpen(prevVal => !prevVal);
        setCurNavId('');
    }
    return <div className="NavMobile">
        <div className="nav-mobile">
            <Logo />
            <span
                className="nav-icon"
                onClick={toggleIsNavOpen}>
                {!isNavOpen
                    ? <MenuOutlined />
                    : <CloseOutlined />
                }
            </span>
        </div>
        {isNavOpen &&
            <div className="nav-menu-mobile">
                <Menu mode="inline" inlineCollapsed={!isNavOpen}>
                    {navSections.map(section =>
                        <Menu.Item key={section.id} onClick={setCurNav(section.id)}>
                            <Link to={section.link || "#"}>{section.name}</Link>

                            {currentNavId === section.id && section.Menu &&
                                <section.Menu key={section.id} type="mobile" toggleIsNavOpen={toggleIsNavOpen}/>
                            }
                        </Menu.Item>
                    )}
                </Menu>
            </div>
        }
    </div>
}