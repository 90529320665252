import { Col, Layout, Row } from 'antd';
import { apiConfig } from 'lemans-brandsites-common/libs/config';
import * as React from 'react';
import './StaticTemplate.style.less';
import { StaticTemplateProps } from "./StaticTemplate.types";

const { Content } = Layout;

export const StaticTemplate = (props: StaticTemplateProps) =>
    <Layout className="StaticTemplate">
        <Content>
            <Row>
                {!props.fullPage
                    ? (
                        <Col lg={12} span={0}>
                            <div className="static-template-image-wrapper">
                                <div
                                    className="static-template-image"
                                    style={{ backgroundImage: props.imageUrl ? `url(${apiConfig.host}${props.imageUrl})` : '' }} />
                            </div>
                        </Col>
                    )
                    : null}
                <Col lg={!props.fullPage ? 12 : 24} span={24}>
                    <div className="container">
                        <div>
                            {props.children}
                        </div>
                    </div>
                </Col>
            </Row>
        </Content>
    </Layout>