import * as React from 'react';
import './Chart.styles.less';
import { IChartProps } from "./Chart.types";

export const Chart = (props: IChartProps) => {
    const headers = props.chart.header[0].columns;
    const data = props.chart.rows;

    return Array.isArray(props.chart.header)
        ? <div className="Chart">
            <h2>{props.title}</h2>
            <table>
                <tbody>
                    <tr>
                        <th key="header-col-partNumber">Part Number</th>
                        {headers.map((header, i) => <th key={`header-col-${i}`}>{header.name}</th>)}
                    </tr>
                    {data.map((row, rowI) => <tr key={`data-row-${rowI}`}>
                        <td>{row.partNumber}</td>
                        {headers.map((header, headerI) => <td key={`data-col-${headerI}`}>
                            {row.columns[headerI].value}
                        </td>)}
                    </tr>)}
                </tbody>
            </table>
        </div>
        : <></>;
}
