import { Col, Row } from 'antd';
import { Page } from 'lemans-brandsites-common/components/Page';
import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import { IEntityMedia, IFeedProduct, IFeedProductGroup } from 'lemans-brandsites-common/libs/ProductFeed/masterFeed.types';
import { useProductGroup } from 'lemans-brandsites-common/libs/ProductFeed/useProductFeed';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { brandName } from 'theme/libs/config';
import { categoryUrl } from 'theme/libs/routes';
import { first } from 'ts-functional';
import { MediaBugs } from '../MediaBugs';
import { MediaSwitcher } from '../MediaSwitcher';
import { ProductBullets } from '../ProductBullets';
import { ProductOptions } from '../ProductOptions';
import { ProductPrice } from '../ProductPrice';
import { ProductRelatedProducts } from '../ProductRelatedProducts';
import { ProductReplacementParts } from '../ProductReplacementParts';
import { ProductSizing } from '../ProductSizing';
import { ProductTitle } from '../ProductTitle';
import './Product.styles.less';
import { IProductProps } from "./Product.types";

export const Product = (props: IProductProps) => {
    const [data, loading] = useProductGroup(+props.productGroupId);

    const product:IFeedProduct | undefined = !!data?.products ? data.products[0] : undefined;
    const productGroup:IFeedProductGroup | undefined = !!product && !!product.productGroups
        ? first(product.productGroups.filter(g => g.productGroupId === +props.productGroupId))
        : undefined;

    const relatedProducts  = !!productGroup && productGroup.relatedEntity ? productGroup.relatedEntity.filter(pg => pg.tag === ""                          ) : [];
    const accessories      = !!productGroup && productGroup.relatedEntity ? productGroup.relatedEntity.filter(pg => pg.tag.indexOf("ACCESSORIES"     ) >= 0) : [];
    const replacementParts = !!productGroup && productGroup.relatedEntity ? productGroup.relatedEntity.filter(pg => pg.tag.indexOf("REPLACEMENT_PART") >= 0) : [];

    const allMedia:IEntityMedia[] = [
        productGroup,
        ...(!!productGroup && productGroup.media ? productGroup.media : [])
] as IEntityMedia[];

    return <Page
        className="Product"
        title={`${brandName} - Product`}>
        <div className="products">
            {loading && <Spinner defaultIndicator={true} size="large" />}
            {!loading && !!product && !!productGroup && <div className="product section">
                <Row gutter={[16, 16]} className="section">
                    <Col span={12}>
                        <div className="breadcrumbs">
                            {!!productGroup.breadcrumb && productGroup.breadcrumb.map(crumb =>
                                <span key={crumb.categoryId} className="breadcrumb">
                                    {![15316].includes(crumb.categoryId) // Helmets category should not be clickable
                                        ? <Link to={categoryUrl(crumb.categoryId)}>{crumb.categoryName}</Link>
                                        : <>{crumb.categoryName}</>
                                    }
                                </span>
                            )}
                        </div>
                        <ProductTitle title={productGroup.productGroupName} caption={productGroup.caption} />
                        <ProductBullets
                            bullets={
                                productGroup.features
                                    ? productGroup.features.filter(feature => feature.featureType === 'bullet')
                                    : []
                            }
                        />
                        <ProductPrice priceRange={productGroup.retailPriceDisplayRange} />
                        <MediaBugs media={allMedia} />
                    </Col>
                    <Col span={12}>
                        <MediaSwitcher media={allMedia} thumbnailUrl='/img/video_icon.png'/>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                { product.productGroups && product.productGroups.length > 1 &&
                                    <div className="section">
                                        <ProductOptions
                                            alignItems={"left"}
                                            productGroups={product.productGroups}
                                            title={"Colorways"}
                                        />
                                    </div>
                                }
                                { !!productGroup.chart && productGroup.chart[0] && productGroup.chart[0].header[0] && productGroup.chart[0].header[0].columns &&
                                    <div className="section">
                                        <ProductSizing chart={productGroup.chart[0]} />
                                    </div>
                                }
                                { relatedProducts.length > 0 &&
                                    <div className="section">
                                        <ProductRelatedProducts
                                            relatedProducts={relatedProducts}
                                            title={"Related Products"}
                                        />
                                    </div>
                                }
                                { accessories.length > 0 &&
                                    <div className="section">
                                        <ProductReplacementParts
                                            alignItems={"left"}
                                            productGroups={accessories}
                                            title="Accessories"
                                        />
                                    </div>
                                }
                                { replacementParts.length > 0 &&
                                    <div className="section">
                                        <ProductReplacementParts
                                            alignItems={"left"}
                                            productGroups={replacementParts}
                                            title="Replacement Parts"
                                        />
                                    </div>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>}
        </div>
    </Page>
}
