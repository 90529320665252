import { FilePdfOutlined, GlobalOutlined } from '@ant-design/icons';
import { Col, Menu, Row } from 'antd';
import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import { useCustomFields } from 'lemans-brandsites-common/libs/useCustomFields';
import * as React from 'react';
import { Image } from '../Image';
import './CatalogMenu.styles.less';
import { ICatalogData, ICatalogMenuProps } from "./CatalogMenu.types";

export const CatalogMenu = (props: ICatalogMenuProps) => {
    const catalogs = useCustomFields<ICatalogData>("header_settings");
    
    if(props.type === "desktop") {              
        return <div className="CatalogMenu">
            <Spinner isLoading={catalogs.isLoading} defaultIndicator={true} size="large">
            <Row>
                {catalogs.data?.catalog_details.map((catalog, i) =>
                    <Col key={`catalog-${i}`} span={12}>
                        <Row>
                            <Col span={12}>
                                <div className="catalog__image">
                                    <Image src={catalog.catalog_thumb} />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Row className="catalog__link-container">
                                    <Col span={24}>
                                        <div className="catalog__link">
                                            <a href={catalog.pdf_download_link} target="_blank">
                                                <div><FilePdfOutlined /></div>
                                                <div>Download PDF</div>
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="catalog__link-container">
                                    <Col span={24}>
                                        <a href={catalog.catalog_flipper_link} target="_blank">
                                            <div className="catalog__link">
                                                <div><GlobalOutlined /></div>
                                                <div>View Digital Version</div>
                                            </div>
                                        </a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                )}
            </Row>
            </Spinner>
         </div>
    } else {
          return <>
            {catalogs.isLoading && <Spinner defaultIndicator={true} size="large" />} 
            {!catalogs.isLoading &&
                catalogs.data?.catalog_details.map((catalog, i) =>
                    <>
                        <Menu.Item key={`mobCatalogMenu${i}catalog-pdf`} onClick={props.toggleIsNavOpen}>
                            <a href={catalog.pdf_download_link} target="_blank" key={`mobCatalogMenu${i}${catalog.catalog_name}`}>{catalog.catalog_name} - PDF</a>
                        </Menu.Item>
                        <Menu.Item key={`mobCatalogMenu${i}${catalog.catalog_name}`} onClick={props.toggleIsNavOpen}>
                            <a href={catalog.catalog_flipper_link} target="_blank" key={`mobCatalogMenu${i}${catalog.catalog_name}`}>{catalog.catalog_name} - Digital</a>
                        </Menu.Item>
                    </>
                )}
            </>
    }
}