import { Col, Row } from 'antd';
import * as React from "react";
import './DealerListItem.styles.less';
import { DealerListItemProps } from "./DealerListItem.types";

export const DealerListItem = (props: DealerListItemProps) => {
    const handleOnClick = () => {
        if (props.onClick) {
            props.onClick(props.index);
        }
    }

    return (
        <div className={'dealer'}>
            <Row gutter={16}>
                <Col xl={16}>
                    <h3 className="dealer__name" onClick={handleOnClick}>{props.index + 1}. {props.dealerData.dealerName}</h3>
                    <div>{props.dealerData.dealerAddressLine1}{props.dealerData.dealerAddressLine2 ? `, ${props.dealerData.dealerAddressLine2}` : ''}</div>
                    <div>{props.dealerData.dealerAddressCity}, {props.dealerData.dealerAddressState} {props.dealerData.dealerAddressZipCode}</div>
                    <div>PH: {props.dealerData.dealerPhoneNumber} <strong>&middot;</strong> {props.dealerData.distance} miles</div>
                </Col>
                <Col xl={8}>
                    {props.dealerData.dealerEmailAddress &&
                        <a href={`mailto:${props.dealerData.dealerEmailAddress}`}>Email</a>
                    }
                    {props.dealerData.dealerWebSiteUrl &&
                        <a href={props.dealerData.dealerWebSiteUrl} target='_blank'>Website</a>
                    }
                </Col>
            </Row>
        </div>
    );
}
