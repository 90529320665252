import { Col, Modal, Row } from 'antd';
import { imageUrl } from 'theme/libs/util';
import { IFeedProductRelatedEntity } from 'lemans-brandsites-common/libs/ProductFeed/masterFeed.types';
import * as React from 'react';
import { useState } from 'react';
import './ProductReplacementParts.styles.less';
import { IProductReplacementPartsProps } from "./ProductReplacementParts.types";

export const ProductReplacementParts = (props: IProductReplacementPartsProps) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [curReplacementPart, setCurReplacementPart] = useState<IFeedProductRelatedEntity>();
    const [data, setData] = useState<any>(null);

    React.useEffect(() => {
        if(!!curReplacementPart && curReplacementPart !== undefined) {
            fetch(`/part-source/productGroup/${curReplacementPart?.relatedId}/part`)
            .then(response => response.json())
            .then(json => setData(json))
            .catch(error => console.error(error));
        }
    }, [curReplacementPart]);

    const getAlignItemsClass = () => {
        switch (props.alignItems) {
            case 'left': {
                return 'product-replacement-part--left';
            }
            case 'center': {
                return 'product-replacement-part--center';
            }
            case 'right': {
                return 'product-replacement-part--right';
            }
            default: {
                return '';
            }
        }
    }

    const handleModalOk = () => {
        setIsModalVisible(false);
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    const showModal = (product: any) => () => {
        setCurReplacementPart(product);
        setIsModalVisible(true);
    };

    return <div className="ProductReplacementParts">
        <h2>{props.title}</h2>
        {/* <Row gutter={[16, 16]}> */}
        <Row gutter={[16, {xs:8, md:16}]}>
            {props.productGroups.map((product, i) =>
                <Col
                    className={`product-replacement-part ${getAlignItemsClass()}`}
                    key={`product-replacement-part-${product.entityId}-${i}`}
                    onClick={showModal(product)}
                    xs={8}
                    md={4}
                >
                    <div className="product-replacement-part__image">
                        <img
                            src={imageUrl(product, { x: 180, y: 180, t: 'image/jpeg' })}
                            title={product.relatedName}
                        />
                    </div>
                    {product.relatedName &&
                        <div className="product-replacement-part__title">
                            {product.relatedName}
                        </div>
                    }
                    {product.retailPriceLowDisplay && product.retailPriceHighDisplay &&
                        <div className="product-replacement-part__price">
                            {product.retailPriceLowDisplay}
                            {product.retailPriceHigh !== product.retailPriceLow &&
                                <> - {product.retailPriceHighDisplay}</>
                            }
                        </div>
                    }
                </Col>
            )}
        </Row>
        <Modal
            centered={true}
            className="replacement-part-modal"
            footer={null}
            onCancel={handleModalCancel}
            onOk={handleModalOk}
            visible={isModalVisible && !!curReplacementPart}
        >
            { curReplacementPart &&
                <div className="replacement-part">
                    <div className="replacement-part__image">
                        <img
                            src={imageUrl({
                                extension: curReplacementPart.extension,
                                mediaUrl: curReplacementPart.mediaUrl,
                                prefixUrl: curReplacementPart.prefixUrl
                            }, { x: 480, y: 480, t: 'image/jpeg' })}
                            title={curReplacementPart.relatedName}
                        />
                    </div>
                    <h3 className="replacement-part__name">{curReplacementPart.relatedName}</h3>

                    <div className="replacement-part__part-label">{data && data[0] && data[0].punctuatedPartNumber}</div>
                    <div className="replacement-part__price-label">Suggested Retail</div>
                    <div className="replacement-part__price-range">
                        {curReplacementPart.retailPriceLowDisplay}
                        {curReplacementPart.retailPriceHigh !== curReplacementPart.retailPriceLow &&
                            <> - {curReplacementPart.retailPriceHighDisplay}</>
                        }
                    </div>
                </div>
            }
        </Modal>
    </div>;
}