import { imageUrl } from 'theme/libs/util';
import * as React from 'react';
import { Link } from 'react-router-dom';
import './ProductListItem.styles.less';
import { IProductListItemProps } from "./ProductListItem.types";

export const ProductListItem = ({productGroup, showPopup}:IProductListItemProps) => {
    const LinkChildren = () => <>
        <img src={imageUrl(productGroup, { x: 400, y: 400, t: 'image/jpeg' })} /><br/>
        <b>{productGroup.productName}</b><br/>
        {productGroup.retailPriceDisplayRange}
    </>;

    const openProductPopup  = (e:React.MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation();
        e.preventDefault();
        console.log('click');
    }

    return <div className="product-list-item">
        {showPopup && <a href="#" onClick={openProductPopup}><LinkChildren /></a>}
        {!showPopup && <Link to={`/product/${productGroup.productGroupId}`}><LinkChildren /></Link>}
    </div>;
}
