import { SocialMedia } from 'lemans-brandsites-common/components/SocialMedia/SocialMedia.component';
import { useToggle } from 'lemans-brandsites-common/libs/useToggle';
import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { navSections } from '../Header/Header.helpers';
import { Logo } from '../Logo';
import { QuickSearch } from '../QuickSearch';
import './NavDesktop.styles.less';
import { INavDesktopProps } from "./NavDesktop.types";

export const NavDesktop = (props: INavDesktopProps) => {
    const inNav = useToggle();
    const [curNavId, setCurNavId] = useState('');

    const hasMegaMenu = () => curNavId !== 'culture' && curNavId !== 'dealerLocator';

    const setCurNav = (sectionId: string) => (e: any) => {
        setCurNavId(sectionId);
    }

    const resetNavState = () => {
        inNav.off();
        setCurNavId('');
    }
    return <div className="NavDesktop" onMouseLeave={resetNavState}>
        <div className="nav-desktop">
            <Logo />
            <span className="nav-menu-desktop">
                <ul
                    className="nav-menu-desktop-sections"
                    onClick={inNav.toggle}
                >
                    {navSections.map(section =>
                        <li
                            key={section.name}
                            onMouseEnter={setCurNav(section.id)}
                        >
                                <Link
                                    className={curNavId === section.id ? "active" : ""}
                                    to={section.link ? section.link : '#'}
                                >
                                    <span>{section.name}</span>
                                </Link>
                        </li>
                    )}
                </ul>
                <span className="social">
                    <SocialMedia />
                </span>
                <span className="quick-search">
                    <QuickSearch />
                </span>
            </span>
        </div>
        {inNav.isOn && hasMegaMenu() &&
            <div
                className={`nav-mega-menu nav-mega-menu-bg-${curNavId}`}
                onClick={inNav.toggle}
            >
                {navSections.map(section =>
                    curNavId === section.id && section.Menu
                        ? <section.Menu key={section.id} type="desktop" />
                        : null
                )}
            </div>
        }
    </div>
}
