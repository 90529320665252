import { asPage } from 'lemans-brandsites-common';
import { About } from 'theme/components/About';
import { Blog } from 'theme/components/Blog';
import { IBlogProps } from 'theme/components/Blog/Blog.types';
import { Careers } from 'theme/components/Careers';
import { Category } from 'theme/components/Category';
import { Contact } from 'theme/components/Contact';
import { DealerSearch } from 'theme/components/DealerSearch';
import { FourOhFour } from 'theme/components/FourOhFour';
import { Homepage } from 'theme/components/Homepage';
import { Instagram } from 'theme/components/Instagram';
import { OnlineDealers } from 'theme/components/OnlineDealers';
import { PrivacyPolicy } from 'theme/components/PrivacyPolicy';
import { Product } from 'theme/components/Product';
import { Search } from 'theme/components/Search';
import { TermsAndConditions } from 'theme/components/TermsAndConditions';
import { TrademarkAttribution } from 'theme/components/TrademarkAttribution';
import { Videos } from 'theme/components/Videos';

export const categoryUrl = (categoryId:number | string) => `/category/${categoryId}`;
export const productUrl  = ( productId:number | string) => `/product/${productId}`;

export const routes = [
    {route: "/",                            exact: true,  component: asPage<any>(Homepage)},
    {route: categoryUrl(":categoryId"),     exact: true,  component: asPage<any>(Category)},
    {route: productUrl(":productGroupId"),  exact: true,  component: asPage<any>(Product)},
    {route: "/blog",                        exact: true,  component: Blog },
    {route: "/blog/:slug",                  exact: true,  component: asPage<IBlogProps>(Blog)},
    {route: "/culture",                     exact: true,  component: asPage<any>(Instagram)},
    {route: "/videos",                      exact: true,  component: asPage<any>(Videos)},
    {route: "/search",                      exact: true,  component: asPage<any>(Search)},
    {route: "/careers",                     exact: true,  component: asPage<any>(Careers)},
    {route: "/contact",                     exact: true,  component: asPage<any>(Contact)},
    {route: "/about",                       exact: true,  component: asPage<any>(About)},
    {route: "/dealer-search",               exact: true,  component: asPage<any>(DealerSearch)},
    {route: "/online-dealers",              exact: true,  component: OnlineDealers},
    {route: "/privacy-policy",              exact: true,  component: asPage<any>(PrivacyPolicy)},
    {route: "/terms-and-conditions",        exact: true,  component: asPage<any>(TermsAndConditions)},
    {route: "/trademark-attribution",       exact: true,  component: asPage<any>(TrademarkAttribution)},
    {route: "",                             exact: false, component: asPage<any>(FourOhFour)},
];