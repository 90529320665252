import { Button } from 'antd';
import * as React from "react";
import './OnlineDealerItem.styles.less'
import { OnlineDealerItemProps } from "./OnlineDealerItem.types";

export const OnlineDealerItem =(props: OnlineDealerItemProps) => {
    const buildAddressLine2 = () => {
        let output = '';

        if (props.data.typeOfDealerDesc === 'Domestic') {
            output += `${props.data.dealerAddressCity}, ${props.data.dealerAddressState} ${props.data.dealerAddressZipCode}`;
        } else {
            output += `${props.data.dealerAddressCity} ${props.data.dealerAddressZipCode}, ${props.data.dealerAddressCountry}`;
        }

        return output;
    }

    return (
        <div className={'online-dealer-item-wrapper--div'}>
            <h2>{props.data.dealerName}</h2>
            <p className={'online-dealer-item-address--p'}>
                {props.data.dealerAddressLine1}{props.data.dealerAddressLine2 ? `, ${props.data.dealerAddressLine2}` : ''}<br />
                {buildAddressLine2()}
            </p>
            <p className={'online-dealer-item-tel--p'}>
                PH: {props.data.dealerPhoneNumber}
            </p>
            <a href={props.data.dealerWebSiteUrl as string} target={'_blank'}>
                <Button className="styled-button-wrapper--button">Website</Button>
            </a>
        </div>
    );
}