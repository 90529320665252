import { Col, Row } from 'antd';
import { imageUrl } from 'theme/libs/util';
import * as React from 'react';
import './MediaBugs.styles.less';
import {IMediaBugsProps} from "./MediaBugs.types";

export const MediaBugs = (props:IMediaBugsProps) => !!props.media
    ? <Row className="product-bugs">
        {props.media.filter(media => media.entityClass === "Domain" && media.tag.substr(0, 4) === 'BUG_').map(media =>
            <Col key={media.mediaId} xs={6}>
                <img src={imageUrl(media)} />
            </Col>
        )}
    </Row>
    : null;