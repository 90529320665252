import { Row } from 'antd';
import { SocialMedia } from 'lemans-brandsites-common/components/SocialMedia/SocialMedia.component';
import * as React from 'react';
import { CategoryMenu } from '../CategoryMenu';
import { Logo } from '../Logo';
import './Footer.styles.less';
import { IFooterProps } from "./Footer.types";

export const Footer = (props: IFooterProps) => {
    return <div className="Footer">
        <div className="top-row">
            <Row>
                <CategoryMenu
                    preventImage={true}
                    type={'desktop'}
                    menuType="bottom"
                />
            </Row>
            <span className="social">
                <SocialMedia />
            </span>
        </div>
        <div className="bottom-row">
            <Logo />
            <ul>
                <li>
                    © {new Date().getFullYear()} Lemans Corporation. All Rights Reserved.
                </li>
                <li>
                    <a href="http://cpsc.lemansnet.com/" target="_blank">Conformity Certifications</a>
                </li>
            </ul>
        </div>
    </div>
}