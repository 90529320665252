import { Button, Col, Input, notification, Row, Select, Spin } from 'antd';
import { api } from 'lemans-brandsites-common/libs/api';
import { useToggle } from 'lemans-brandsites-common/libs/useToggle';
import * as React from 'react';
import './Newsletter.styles.less';
import { INewsletterProps } from "./Newsletter.types";

export const Newsletter = (props: INewsletterProps) => {
    const [   firstName,   setFirstName ] = React.useState("");
    const [    lastName,   setLastName  ] = React.useState("");
    const [       email,       setEmail ] = React.useState("");
    const [     zipCode,     setZipCode ] = React.useState("");
    const [ vehicleType, setVehicleType ] = React.useState("");
    const onInputChange = (f:(val:string) => void) => (e:React.ChangeEvent<HTMLInputElement>) => {f(e.currentTarget.value);}

    const loading = useToggle();

    const submit = () => {
        loading.on();
        
        api.post(`lemans/rest/domain/11/form/z1r_newsletter_signup/submit`, {
            form_1_firstName: firstName,
            form_2_lastName: lastName,
            form_3_emailAddress: email,
            form_4_zipCode: zipCode,
            form_5_vehicleType: vehicleType
        }, 'form')
            .then(() => {
                notification.success({message: "You have been signed up for the newsletter!"});
            }).finally(loading.off);
    }

    return <div className="Newsletter">
        <div className="newsletterHeader">
            <Row>
                <Col
                    span={24}
                    xl={12}
                >
                    <div className="newsletter-main">
                        <span>
                            Sign up for our <br/> e-newsletter
                        </span>
                    </div>
                </Col>
                <Col
                    span={24}
                    xl={12}
                >
                    <div className="newsletter-sub">
                        <span>
                            Get exclusive information on events, products, and giveaways.
                        </span>
                    </div>
                </Col>
            </Row>
        </div>
        <div className="newsletterForm">
            <Spin spinning={loading.isOn} >
                <Row>
                    <Col xs={8}>FIRST NAME</Col>
                    <Col xs={16}><Input value={firstName} onChange={onInputChange(setFirstName)} /></Col>

                    <Col xs={8}>LAST NAME</Col>
                    <Col xs={16}><Input value={lastName} onChange={onInputChange(setLastName)} /></Col>

                    <Col xs={8}>EMAIL</Col>
                    <Col xs={16}><Input value={email} onChange={onInputChange(setEmail)} /></Col>

                    <Col xs={8}>ZIP CODE</Col>
                    <Col xs={16}><Input value={zipCode} onChange={onInputChange(setZipCode)} /></Col>

                    <Col xs={8}>I OWN A</Col>
                    <Col xs={16}>
                        <Select<string> value={vehicleType} onChange={setVehicleType} dropdownClassName="vehicleSelect">
                            <Select.Option value="">SELECT</Select.Option>
                            <Select.Option value="V Twin">V-TWIN</Select.Option>
                            <Select.Option value="Metric">METRIC</Select.Option>
                            <Select.Option value="Offroad Vehicle">OFFROAD VEHICLE</Select.Option>
                        </Select>
                        <Button onClick={submit}>SUBMIT</Button>
                    </Col>
                </Row>
            </Spin>
        </div>
    </div>;
}
