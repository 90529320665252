import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import { imageUrl } from 'theme/libs/util';
import { useCategory } from 'lemans-brandsites-common/libs/ProductFeed';
import { IFeedCategory } from 'lemans-brandsites-common/libs/ProductFeed/masterFeed.types';
import * as React from 'react';
import { LandingImage } from '../LandingImage';
import { ProductListItem } from '../ProductListItem';
import './Category.styles.less';
import { ICategoryProps } from "./Category.types";

export const Category = ({categoryId}:ICategoryProps) => {
    const [category, loading] = useCategory(categoryId);

    const CategoryProductList = ({cat}:{cat:IFeedCategory}) => !!cat.productGroups && cat.productGroups.length > 0 && !cat.tag.includes("HIDDEN")
        ? <div className="subcategory">
            <div className="category-product-list">
                <h1>{cat.categoryName}</h1>
                <div className="category-product-list">
                    {(cat.productGroups || []).map(productGroup => <ProductListItem
                        key={productGroup.productGroupId}
                        productGroup={productGroup}
                        showPopup={cat.categoryId === 15322}
                    />)}
                </div>
            </div>
        </div>
        : null;

    return <>
        {loading && <Spinner defaultIndicator={true} size="large" />}
        {!loading && !!category && <div className="category-list">
            {category.categories.map((cat:IFeedCategory) => <div key={cat.categoryId}>
                {!!cat.mediaUrl && <img src={imageUrl(cat)} title={cat.categoryName} />}
                {!cat.mediaUrl && <LandingImage imageUrl={cat.mediaUrl} />}
                <CategoryProductList cat={cat} />
                {(cat.categories || []).map(subCat => <CategoryProductList key={subCat.categoryId} cat={subCat} />)}
            </div>)}
        </div>}
    </>;
}
