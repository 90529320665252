import { Page } from 'lemans-brandsites-common/components/Page';
import * as React from 'react';
import { brandName } from 'theme/libs/config';
import { InstagramFeed } from '../InstagramFeed/InstagramFeed.component';
import './Instagram.styles.less';
import { IInstagramProps } from "./Instagram.types";

export const Instagram = (props: IInstagramProps) =>
    <Page className="Instagram" title={`${brandName} - Instagram`}>
        <div className="container">
            <InstagramFeed />
        </div>
    </Page>;
