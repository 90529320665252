import * as React from 'react';
import { useLocation } from 'react-router';
import { diff } from 'ts-functional';
import { Setter, useSharedState } from 'unstateless';

type Key = string | number;

export const useNavMenuOpenKeys = ():[string[], Setter<Key[]>] => {
    const [openKeys, setOpenKeys] = useSharedState<Key[]>("openMenuKeys", [])();
    const location = useLocation();

    const updateOpenKeys = (keys:Key[]) => {
        setOpenKeys(curKeys => diff(keys, curKeys));
    }

    React.useEffect(() => {
        setOpenKeys([]);
    }, [location.pathname]);

    return [openKeys.map(k => `${k}`), updateOpenKeys];
}
