import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { useToggle } from 'lemans-brandsites-common/libs/useToggle';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import './QuickSearch.styles.less';
import { IQuickSearchProps } from "./QuickSearch.types";

export const QuickSearch = (props:IQuickSearchProps) => {
    const showSearch = useToggle();
    const history = useHistory();

    const [term, setTerm] = React.useState("");
    const updateTerm = (e:React.ChangeEvent<HTMLInputElement>) => {
        setTerm(e.currentTarget.value);
    }

    const search = () => {
        history.push(`/search?q=${term}`);
        showSearch.off();
        setTerm("");
    }

    return <div className={`QuickSearch ${showSearch.isOn && "active"}`}>
        {showSearch.isOn && <Input
            placeholder="Search for..."
            autoFocus
            onChange={updateTerm}
            onPressEnter={search}
        />}
        <SearchOutlined onClick={showSearch.toggle} />
    </div>;
}