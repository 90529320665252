import { Col, Row } from 'antd';
import { apiConfig } from 'lemans-brandsites-common/libs/config';
import * as React from 'react';
import { useEffect, useState } from 'react';
import fallbackImage from "../../../media/images/z1r_01.jpg";
import { IPost } from '../Blog.types';
import './BlogRecentPosts.styles.less';
import { BlogRecentPostsProps } from "./BlogRecentPosts.types";

export const BlogRecentPosts = (props: BlogRecentPostsProps) => {
    const [recentPosts, setRecentPosts] = useState<IPost[]>();

    useEffect(() => {
        if (props.posts) {
            setRecentPosts(props.posts.length > 3 ? props.posts.slice(0, 3) : props.posts);
        }
        console.log('props.posts', props.posts);
    }, [props.posts]);

    const handleFallbackImage = (ev: any) => {
        ev.target.src = fallbackImage;
    }

    return (
        <div className="BlogRecentPosts">
            <h3>Recent Articles</h3>
            <Row>
                {!!recentPosts &&
                    recentPosts.map((post: IPost, index: number) =>
                        <Col span={8} key={index}>
                            <div className="post">
                                <div className="post__content">
                                    <a href={`/blog/${post.slug}`}>
                                        <div className="post__content-overlay" />
                                        <img
                                            className="post__content-image"
                                            onError={handleFallbackImage}
                                            src={`${apiConfig.host}${post?.acf.side_banner}`}
                                        />
                                        <div className="post__content-details">
                                            <h3 dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </Col>
                    )
                }
            </Row>
        </div>
    )
}