import { FilterOutlined } from '@ant-design/icons';
import { Col, Drawer, Row } from 'antd';
import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import { imageUrl } from 'theme/libs/util';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { SearchFacetFilters } from 'theme/components/SearchFacetFilters';
import 'theme/components/SearchResults/SearchResults.styles.less';
import { SelectedFilters } from 'theme/components/SelectedFilters';
import { ISearchResultsProps } from "./SearchResults.types";

const defaultProductUrl = (productId: number, productGroupId: number) => {
    return `/products/${productId}/productGroup/${productGroupId}`;
}

export const SearchResults = (props: ISearchResultsProps) => {
    const getProductGroupUrl = props.url || defaultProductUrl;

    const [isDrawerVisible, setIsDrawerVisible] = React.useState(false);

    const onSearch = async () => {
        if (props.onSearch) {
            props.onSearch();
        }
    }

    const showDetailedFilters = () =>
        setIsDrawerVisible(true);
    const onCloseDrawer = () =>
        setIsDrawerVisible(false);

    // TODO: figure out why the opened drawer is getting closed after applying the filters

    const FilterDrawer = () =>
        <Drawer
            placement='left'
            closable={true}
            onClose={onCloseDrawer}
            visible={isDrawerVisible}
            className="narrowResultsDrawer"
        >
            <Row className="fitment-selected-filters--container" justify="space-around" align="middle">
                <Col span={24}>
                    <SelectedFilters facetHeader={props.mmyResults.header} onSearch={onSearch} />
                </Col>
                <Col span={24}>
                    <SearchFacetFilters facetHeader={props.mmyResults.header} availableFacets={props.mmyResults.facets} />
                </Col>
            </Row>
        </Drawer>

    return (
        <>
            <Spinner isLoading={props.isLoading}>
                {props.mmyResults && props.mmyResults.header &&
                    <Row className="fitment-selected-filters--container" justify="space-between" align="middle">
                        <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                            <SelectedFilters facetHeader={props.mmyResults.header} onSearch={onSearch} key="selected_filter__key" />
                        </Col>
                        {!props.hideFilters && <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24} >
                            <div className="filterbutton" onClick={showDetailedFilters} key="styled_button__key"><FilterOutlined /> narrow your results</div>
                            <FilterDrawer />
                        </Col>}
                    </Row>
                }
                {props.mmyResults && props.mmyResults.hitlist && props.mmyResults.hitlist.productGroups &&
                    <Row gutter={48} justify="start" className="search-results--container">
                        {
                            props.mmyResults.hitlist.productGroups.map((response: any, index: number) =>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12} key={`col-key-${index}`} className="resultGridCol">
                                    <Link
                                        to={getProductGroupUrl(response.productId, response.id)}
                                        title={response.productGroupName}
                                        key={response.id}
                                    >
                                        <img
                                            key={`key${index}`}
                                            src={imageUrl(response, { x: 400, y: 400, t: 'image/jpeg' })}
                                            className={'product-search--img'}
                                        />
                                    </Link>
                                    <div key={`productname${index}`} className="product-image--title">{response.productName}</div>
                                </Col>
                            )
                        }
                    </Row>
                }
                {props.mmyResults && props.mmyResults.hitlist && props.mmyResults.hitlist.productGroups.length === 0 &&
                    <Row gutter={48} justify="start" align="bottom" className="search-results--container">
                        <Col span={24}>
                            <div className="noResultsDiv">No results found: We were unable to find any search results using the selected filters.</div>
                        </Col>
                    </Row>
                }
            </Spinner>
        </>
    );
}
