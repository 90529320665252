import { Col, Row } from 'antd';
import { siteConfig } from 'lemans-brandsites-common/libs/config';
import * as React from 'react';
import './LandingImage.styles.less';
import { ILandingImageProps } from "./LandingImage.types";

export const LandingImage = (props: ILandingImageProps) =>
    <Row className="LandingImage">
        <Col sm={24} xs={0}>
            <div
                className="landing-image"
                style={{ backgroundImage: `url(${siteConfig.imageBase}${props.imageUrl})` }}
            >
                {props.children}
            </div>
        </Col>
    </Row>