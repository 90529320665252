import { Col, Row } from 'antd';
import { Page } from 'lemans-brandsites-common/components/Page';
import { SpinnerCss } from 'lemans-brandsites-common/components/SpinnerCss';
import { apiRestHandleSideEffects } from 'lemans-brandsites-common/libs/apiHandleSideEffects';
import * as moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { brandName, localSiteUrl } from 'theme/libs/config';
import { FourOhFour } from '../FourOhFour';
import { StaticTemplate } from '../StaticTemplate';
import './Blog.styles.less';
import { BlogProps, IPost, IProduct } from "./Blog.types";
import { BlogRecentPosts } from "./BlogRecentPosts";

export const Blog = (props: BlogProps) => {
    const [currentPost, setCurrentPost] = useState<IPost>();
    const [displayFourOhFour, isLoading, data] = apiRestHandleSideEffects('wp/v2/posts');
    const [posts, setPosts] = useState<IPost[]>([]);

    useEffect(() => {
        if (data) {
            setPosts(data);
        }
    }, [data]);

    useEffect(() => {
        if (data && posts.length !== 0) {
            const blog: IPost = props.slug ? data.find((post: IPost) => {
                return post.slug === props.slug;
            }) : data[0];
            setCurrentPost(blog);
        }
    }, [posts]);

    return (
        <>
            { displayFourOhFour && <FourOhFour />}
            { isLoading && <SpinnerCss />}
            { !isLoading && !!currentPost && !!posts &&
                <Page
                    className="Blog"
                    description={`${brandName} - Blog`}
                    robots={{ follow: true, index: true }}
                    title={`${brandName} - Blog`}
                >
                    <StaticTemplate hasHeader={false} imageUrl={currentPost?.acf.side_banner}>
                        <Row
                            className="current-post"
                        >
                            <Col span={24}>
                                <h2 className="current-post__title" dangerouslySetInnerHTML={{ __html: currentPost.title.rendered }} />
                                <p className="current-post__date">{moment(currentPost.date).format("MMMM d, YYYY")}</p>
                                <div className="current-post__content" dangerouslySetInnerHTML={{ __html: currentPost.content.rendered }} />
                            </Col>
                            {!!currentPost.acf && !!currentPost.acf.featured_products &&
                                currentPost.acf.featured_products?.map((product: IProduct, productIndex: number) => {
                                    return (
                                        <Col span={24} className="current-post-product" key={productIndex}>
                                            <img
                                                className="current-post-product__thumbnail"
                                                height={product.product_image.height}
                                                width={product.product_image.width}
                                                src={`${localSiteUrl}${product.product_image.url}`}
                                                alt={product.product_image.title} />
                                            <a href={product.product_link}>
                                                <h3 className="current-post-product__name">{product.product_name}</h3>
                                            </a>
                                            <p dangerouslySetInnerHTML={{ __html: product.product_description }} />
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                        <BlogRecentPosts posts={posts} />
                    </StaticTemplate>
                </Page>
            }
        </>
    )
}