import { Col, Row } from 'antd';
import { Image } from 'lemans-brandsites-common/components/Image/Image.component';
import { Page } from 'lemans-brandsites-common/components/Page';
import { PlaceholderImage } from 'lemans-brandsites-common/components/PlaceholderImage/PlaceholderImage.component';
import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import { useCustomFields } from 'lemans-brandsites-common/libs/useCustomFields';
import * as React from 'react';
import { brandName } from 'theme/libs/config';
import { FourOhFour } from '../FourOhFour';
import { LandingImage } from '../LandingImage';
import { Newsletter } from '../Newsletter';
import { SectionTitle } from '../SectionTitle';
import './Homepage.styles.less';
import { IHomepageCustomFields, IHomepageProps } from "./Homepage.types";

export const Homepage = (props: IHomepageProps) => {
    // const isLoading = 
    const homepageData = useCustomFields<IHomepageCustomFields>("home");
    const mainImages = !!homepageData.data ? homepageData.data.images.filter(i => i.image_type === "Main Image") : [];
    const subImages = !!homepageData.data ? homepageData.data.images.filter(i => i.image_type === "Sub Image") : [];

    return <>
        {homepageData.fourOhFour && <FourOhFour />}
        {homepageData.isLoading && <Spinner defaultIndicator={true} size="large" />}
        {!homepageData.isLoading && !homepageData.fourOhFour &&
            <Page className="Homepage" title={`${brandName} - Home`}>
                {mainImages.map((image, i) =>
                    <LandingImage
                        key={`landing-image-${i}`}
                        imageUrl={image.image}
                    >
                        <SectionTitle
                            buttonUrl={image.link}
                            buttonUrlText={image.link_text}
                            side={image.side}
                            verticalPos={image.vertical_pos}
                            tagline={image.tagline}
                            title={image.headline}
                        />
                    </LandingImage>
                )}
                <Row>
                    {subImages.map((image, i) =>
                        <Col
                            key={i}
                            span={24}
                            lg={8}
                        >
                            <div className="homepage-category-image">
                                {!!image.image && <Image src={image.image} title={`${image.headline} - ${image.tagline}`}/>}
                                {!image.image && <PlaceholderImage width={512} height={512} />}
                                <SectionTitle
                                    buttonUrl={image.link}
                                    buttonUrlText={image.link_text}
                                    side={image.side}
                                    verticalPos={image.vertical_pos}
                                    tagline={image.tagline}
                                    title={image.headline}
                                />
                            </div>
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col span={24}>
                        <div className="container container--lg">
                            <Newsletter />
                        </div>
                    </Col>
                </Row>
            </Page>
        }
    </>
}