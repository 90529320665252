import { page } from 'lemans-brandsites-common/libs/GetPageFromWP';
import React, { useEffect, useState } from 'react';
import { IInstagramFeedProps } from "./InstagramFeed.types";

// Cannot get the feed scripts to re-run properly when the containing page is navigated away from and then back again.
// So, force the whole app to reload completely whenever the instagram feed is re-mounted when it has alredy been loaded.
// let isLoaded = false;

export const InstagramFeed = ({}:IInstagramFeedProps) => {
    const [data, setData] = useState<any>();
    
    useEffect(() => {
        // Fetch the Instagram feed data.  This assumes a 'instagram-feed' page exists with a single instagram feed block as the content
        page('instagram-feed').then(setData);

        // Reload the page if we're showing the feed again after the first load.
        // if(isLoaded) {location.reload();}
    }, []);

    const loadScripts = () => {
        const addedScripts:any[] = []; // keep track of the added scripts

        if (!!data) {
            // isLoaded = true;

            // Extract the feed settings from the content and save to a global variable
            const htmlString = data.content.rendered;
            const scriptRegex = /<script type="text\/javascript">(.*?)<\/script>/;
            const match = htmlString.match(scriptRegex);    
            if (match && match[1]) {
                window.eval(match[1]);
            }
    
            // Add the jQuery and feed scripts dynamically
            const scripts = [
                "https://code.jquery.com/jquery-3.6.0.min.js",
                "/wp-content/plugins/instagram-feed-pro/js/sbi-scripts.min.js?ver=5.8.3",
            ];
            scripts.forEach(url => {
                const script = document.createElement("script");
                script.src = url;
                script.async = true;
                document.body.appendChild(script);
                addedScripts.push(script);
            });
    
        }
        
        // Return cleanup function to remove the appended scripts on unmount
        return () => {
            addedScripts.forEach(script => {
                document.body.removeChild(script);
            });
        };
    };
    
    // Load the scripts after the data has loaded
    useEffect(loadScripts, [data]);

    return !!data ? <>
        <link rel="stylesheet" href="/wp-content/plugins/instagram-feed-pro/css/sbi-styles.min.css?ver=5.8.3" />
        <div dangerouslySetInnerHTML={{__html: data.content.rendered}} />
    </> : null;
}
