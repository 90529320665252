import { Page } from 'lemans-brandsites-common/components/Page';
import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import { pageRestHandleSideEffects } from 'lemans-brandsites-common/libs/apiHandleSideEffects';
import * as React from 'react';
import { brandName } from 'theme/libs/config';
import { FourOhFour } from '../FourOhFour';
import './Careers.styles.less';
import {ICareersProps} from "./Careers.types";

export const Careers = (props:ICareersProps) => {
    const [content, setContent] = React.useState<string>('');
    const [fourOhFour, isLoading, data] = pageRestHandleSideEffects('careers');
    const [title, setTitle] = React.useState<string>('');

    React.useEffect(() => {
        setContent(data?.content.rendered ? data?.content.rendered : '');
        setTitle(data?.title.rendered ? data?.title.rendered : '');
    }, [data]);

    return <>
        {fourOhFour && <FourOhFour />}
        {isLoading && <Spinner defaultIndicator={true} size="large" />}
        {!isLoading && !fourOhFour && data &&
            <Page className="Careers" title={`${brandName} - ${title}`}>
                <div className="container">
                    <h1>{title}</h1>
                </div>
                <div className="container" dangerouslySetInnerHTML={{ __html: content }}/>
            </Page>
        }
    </>
}
