import { Page } from "lemans-brandsites-common/components/Page";
import { Videos as BaseComponent } from "lemans-brandsites-common/components/Videos/Videos.component";
import * as React from 'react';
import { brandName, youTubeKey, youTubePlaylistId } from 'theme/libs/config';
import './Videos.styles.less';
import { IVideosProps } from "./Videos.types";

export const Videos = (props: IVideosProps) =>
    <Page className="Videos" title={`${brandName} - Videos`}>
        <BaseComponent
            youTubeKey={youTubeKey}
            youTubePlaylistId={youTubePlaylistId}
        />
    </Page>;