import { Page } from 'lemans-brandsites-common/components/Page';
import { IWordPressResponse } from 'lemans-brandsites-common/libs/apiHandleSideEffects';
import * as React from 'react';
import { useState } from 'react';
import { brandName } from 'theme/libs/config';
import './FourOhFour.styles.less';
import { IFourOhFourProps, IPost } from "./FourOhFour.types";

export const FourOhFour = (props: IFourOhFourProps) => {
    const [posts,] = useState<IPost[] | IWordPressResponse | null>(null);
    // setPosts

    return <Page
        className="FourOhFour"
        title={`${brandName} - 404`}>
        <div className="container">
            <h1>Oops! That page can’t be found.</h1>
            {posts &&
                <>
                    <h3>It looks like nothing was found at this location. Maybe try one of the links below?</h3>
                    <ul>
                        {
                            (posts as IPost[]).map((post: IPost) =>
                                <li key={post.id}>
                                    <a
                                        dangerouslySetInnerHTML={{ __html: post.title.rendered }}
                                        href={post.link}
                                    />
                                </li>
                            )
                        }
                    </ul>
                </>
            }
        </div>
    </Page>
}