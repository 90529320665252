import { CloseOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Col, Modal, Row } from 'antd';
import { IEntityMedia } from 'lemans-brandsites-common/libs/ProductFeed/masterFeed.types';
import { useToggle } from 'lemans-brandsites-common/libs/useToggle';
import * as React from 'react';
import 'theme/components/MediaSwitcher/MediaSwitcher.styles.less';
import { imageUrl } from 'theme/libs/util';
import { IMediaSwitcherProps } from "./MediaSwitcher.types";

export const MediaSwitcher = (props:IMediaSwitcherProps) => {
    const [curMedia, setCurMedia] = React.useState(0);
    const select = (index:number) => () => {setCurMedia(index);}

    const getVideoId = (vid?:IEntityMedia) => !!vid ? vid.mediaUrl.replace("youtube-", "") : "";
    const [videoId, setVideoId] = React.useState("");
    const [threeSixtyMediaUrl, setThreeSixtyMediaUrl] = React.useState("");
    const updateVideo = (vid?:IEntityMedia) => () => {setVideoId(getVideoId(vid));}
    const updateThreeSixtyVideo = (vid?:IEntityMedia) => () => {
        modal.on();
        if(!!vid) {
            setThreeSixtyMediaUrl('/'+vid.prefixUrl+'/'+vid.mediaUrl+'.'+vid.extension);
        }
    }

    const normalImage = (media:IEntityMedia) => 
        media.mediaTypeDescription === "image" &&
        media.tag === "";

    const images = !!props.media ? props.media.filter(normalImage) : [];

    const video = (media:IEntityMedia) => media.mediaTypeDescription === "video" && media.tag.toLowerCase() !== 'threesixty';
    const threeSixtyVideo = (media:IEntityMedia) => media.mediaTypeDescription === "video" && media.tag.toLowerCase() === 'threesixty';
    const modal = useToggle();
    
    const thumbnailUrl = (vid:IEntityMedia) => props.thumbnailUrl || `http://img.youtube.com/vi/${getVideoId(vid)}/sddefault.jpg`;

    return props.media && props.media.length > 0
        ? <div className="media-switcher">
            {!!videoId && <div className="video-container">
                <div className="video-close" onClick={updateVideo()}>
                    Close <CloseOutlined />
                </div>
                <iframe
                    allowFullScreen
                    className="video"
                    frameBorder={0}
                    src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                    allow="autoplay"
                    style={{width: "100%"}}
                />
            </div>}
            {!!threeSixtyMediaUrl && <Modal title="360 View" visible={modal.isOn} onCancel={modal.off} footer={null} width={'80%'} destroyOnClose>
                <video 
                    className='threeSixtyVideo'
                    controls
                    loop
                    autoPlay
                    src={`https://asset.lemansnet.com/${threeSixtyMediaUrl}`}
                />
            </Modal>}
            <Row>
                <Col xs={24}>
                    <img src={imageUrl(images[curMedia], { x: 512, y: 512, t: 'image/jpeg' })}/>
                </Col>
                {images.map((media, i) =>
                    <Col className="media-item" key={`image-${media.mediaId}`} xs={6}>
                        <img src={imageUrl(media, { x: 256, y: 256, t: 'image/jpeg' })} onClick={select(i)}/>
                    </Col>
                )}
                {props.media.filter(video).map((vid, i) =>
                    <Col className="media-item video-item" key={`video-${i}`} xs={6} onClick={updateVideo(vid)}>
                        {props.showHoverIcon && <div className="icon"><VideoCameraOutlined /></div>}
                        <img src={thumbnailUrl(vid)}/>
                    </Col>
                )}
                {props.media.filter(threeSixtyVideo).map((vid, i) =>
                    <Col className="media-item threesixty-video-item" key={`video-${i}`} xs={6} onClick={updateThreeSixtyVideo(vid)}>
                        {<img src="/img/360_icon.png?x=256&y=256&t=image/jpeg"/>}
                    </Col>
                )}
            </Row>
        </div>
        : <div>No media</div>;
}