import { Col, Menu, Row } from 'antd';
import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import { IFeedCategory } from 'lemans-brandsites-common/libs/ProductFeed/masterFeed.types';
import { useCategoryNav } from 'lemans-brandsites-common/libs/ProductFeed/useFeed';
import { useCustomFields } from 'lemans-brandsites-common/libs/useCustomFields';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { categoryUrl } from 'theme/libs/routes';
import { ICatalogData } from '../CatalogMenu/CatalogMenu.types';
import { CompanyMenu } from '../CompanyMenu';
import { Image } from '../Image';
import { SectionTitle } from '../SectionTitle';
import { useNavMenuOpenKeys } from './CategoryMenu.helpers';
import './CategoryMenu.styles.less';
import { ICategoryMenuProps } from "./CategoryMenu.types";

const { SubMenu } = Menu;

export const CategoryMenu = (props: ICategoryMenuProps) => {
    const [nav, loading] = useCategoryNav();
    const headerSettings = useCustomFields<ICatalogData>("header_settings");
    const splash = headerSettings.data?.category_splash_image;
    const [openKeys, setOpenKeys] = useNavMenuOpenKeys();

    const isVisible = (cat:IFeedCategory) => cat.tag.indexOf("HIDDEN") === -1;

    return <>
        {loading && <Spinner defaultIndicator={true} size="large" />}
        {!loading && props.type === "desktop"
            ? <Row className="catalog">
                {nav?.catalogs[0].categories.filter(isVisible).map(cat =>
                    <Col
                        key={cat.categoryId}
                        lg={4}
                        md={12}
                        xs={24}
                    >
                        <SectionTitle
                            side="left"
                            title={cat.categoryName}
                            verticalPos="top"
                        />
                        <ul>
                            {(cat.categories || []).filter(isVisible).map(child =>
                                <li key={`${cat.categoryId}-${child.categoryId}`}>
                                    <Link to={categoryUrl(child.categoryId)}>
                                        {child.categoryName}
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </Col>
                )}
                {props.menuType === "bottom" &&
                    <CompanyMenu />
                }                
                {!props.preventImage &&
                    <Col lg={8} md={0}>
                        <div className="catalog-image">
                            <Image src={splash?.image || ""} />
                            <div className="catalog-image-text">
                                <div className="section-title">
                                    <span className="underline-accent">
                                        {splash?.title}
                                    </span>
                                </div>
                                <p>{splash?.tag_line}</p>
                                <Link to={splash?.link || ""}>{splash?.link_label}</Link>
                            </div>
                        </div>
                    </Col>
                }
            </Row>
            : !loading && props.type === 'mobile'
                ? 
                props.menuType === "bottom" ? 
                    <Menu mode="inline" openKeys={openKeys} onOpenChange={setOpenKeys}>
                        {nav?.catalogs[0].categories.filter(isVisible).map(child => 
                            <SubMenu key={child.categoryName} title={child.categoryName}>
                                {(child.categories || []).filter(isVisible).map(childChild =>
                                    <Menu.Item key={childChild.categoryName}>
                                        <Link to={categoryUrl(childChild.categoryId)}>
                                            {childChild.categoryName}
                                        </Link>
                                    </Menu.Item>
                                )}
                            </SubMenu>
                            )}
                    </Menu>
                    :
                     <Menu mode="inline" openKeys={openKeys} onOpenChange={setOpenKeys}>
                         {nav?.catalogs[0].categories.filter(isVisible).map(child => 
                            <SubMenu key={`mobTopMenu${child.categoryName}`} title={child.categoryName}>
                                {(child.categories || []).filter(isVisible).map(childChild =>
                                    <Menu.Item key={`mobTopMenu${child.categoryName}${childChild.categoryName}`}>
                                        <Link to={categoryUrl(childChild.categoryId)}>
                                            {childChild.categoryName}
                                        </Link>
                                    </Menu.Item>
                                )}
                            </SubMenu>
                            )}
                    </Menu>
                : null
        }
    </>
}