import { Chart } from 'lemans-brandsites-common/components/Chart/Chart.component';
import * as React from 'react';
import './ProductSizing.styles.less';
import { IProductSizingProps } from "./ProductSizing.types";

export const ProductSizing = (props: IProductSizingProps) =>
    <div className="ProductSizing">
        <Chart
            chart={props.chart}
            title="Sizing & Part Numbers"
        />
    </div>
