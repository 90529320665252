import { Col } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { SectionTitle } from '../SectionTitle';
import './CompanyMenu.styles.less';
import {ICompanyMenuProps} from "./CompanyMenu.types";

export const CompanyMenu = (props:ICompanyMenuProps) =>
    <Col>
        <SectionTitle
            side="left"
            title="COMPANY"
            verticalPos="top"
        />
        <ul>
            <li>
                <Link to="/terms-and-conditions">TERMS & SERVICES</Link>
            </li>
            <li>
                <Link to="/about">ABOUT</Link>
            </li>
            <li>
                <Link to="/contact">CONTACT</Link>
            </li>
            <li>
                <Link to="/careers">CAREERS</Link>
            </li>
            <li>
                <Link to="/trademark-attribution">Trademark Attribution</Link>
            </li>
            <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
        </ul>
    </Col>;