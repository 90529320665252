import { Layout } from 'antd';
import * as React from 'react';
import { NavDesktop } from '../NavDesktop';
import { NavMobile } from '../NavMobile';
import './Header.styles.less';
import { IHeaderProps } from "./Header.types";

export const Header = (props: IHeaderProps) => {
    const [yPos, setYPos] = React.useState(window.scrollY);

    const subToWindowScroll = React.useCallback(e => {
        setYPos(window.scrollY);
    }, [yPos]);

    React.useEffect(() => {
        setYPos(window.scrollY);
        window.addEventListener("scroll", subToWindowScroll);

        // Cleanup
        return () => {
            window.removeEventListener("scroll", subToWindowScroll);
        };
    }, [subToWindowScroll]);

    return <Layout.Header
        className={yPos > 0 ? 'header header-detached' : 'header'}
        style={{ width: '100%', zIndex: 1 }}
    >
        <div className="Header">
            <NavMobile />
            <NavDesktop />
       </div>
    </Layout.Header>;
}