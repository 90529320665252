import * as React from 'react';
import { CatalogMenu } from '../CatalogMenu';
import { CategoryMenu } from '../CategoryMenu';

export interface INavMenuSection {
    id: string;
    link?: string;
    name: string;
    Menu: (props:{type: "desktop" | "mobile", toggleIsNavOpen?:any}) => JSX.Element | null;
}

export const navSections: INavMenuSection[] = [
    {id: "products",      Menu: ({type}) => <CategoryMenu type={type} menuType="top"/>, link: undefined,        name: 'Products',     },
    {id: "catalogs",      Menu: ({type}) => <CatalogMenu type={type} toggleIsNavOpen/>,              link: undefined,        name: 'Catalogs'      },
    {id: "culture",       Menu: ()       => null,                         link: '/culture',       name: 'Culture'       },
    {id: "dealerLocator", Menu: ()       => null,                         link: '/dealer-search', name: 'Dealer Locator'},
];
