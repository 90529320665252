import * as React from 'react';
import logo from "../../assets/images/z1r_logo.png";
import './Logo.styles.less';
import { ILogoProps } from "./Logo.types";

export const Logo = (props: ILogoProps) =>
    <a href="/">
        <img
            alt="Z1R Logo"
            className="logo"
            src={logo}
        />
    </a>;
