import { IFeedProductFeatureInfo } from 'lemans-brandsites-common/libs/ProductFeed/masterFeed.types';
import * as React from 'react';
import { useEffect, useState } from 'react';
import './ProductBullets.styles.less';
import { IProductBulletsProps } from "./ProductBullets.types";

export const ProductBullets = (props: IProductBulletsProps) => {
    const [sortedBullets, setSortedBullets] = useState<IFeedProductFeatureInfo[]>();

    useEffect(() => {
        const sorted = props.bullets.sort((a, b) => a.sequence - b.sequence);
        setSortedBullets(sorted);
    }, [props.bullets]);

    return <div className="ProductBullets">
        <ul>
            {sortedBullets?.map((bullet, i) => {
                    if(bullet.feature.includes('<a')) {
                        const doc = new DOMParser().parseFromString(bullet.feature, "text/html");
                        return <span key={`bullet-${i}`} dangerouslySetInnerHTML={{__html: doc.body.innerHTML}} />
                    } else {
                        return <li key={`bullet-${i}`}>{bullet.feature}</li>
                    }
                }
            )}
        </ul>
    </div>;
}
